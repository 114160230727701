<template>
  <div>
    <iframe id="inner" src="http://titeam.krray.com:26108/products/wlight.html" width="100%"></iframe>
  </div>
</template>
<script>
export default {
    mounted(){
        document.getElementById('inner').style.height =window.screen.height+'px';
    }
}
</script>
<style scoped>
#inner{background-color: #fff;border: none;}
</style>